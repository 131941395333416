import React, { useEffect, useState } from "react";
import { useParams, Link } from "react-router-dom";
import {
	getLunaUrl,
	getNumericalHeader,
	axios,
	processNextpageUrl,
} from "../utils";
import { CustomInput } from "reactstrap";
import { toast } from "react-toastify";
import { Row, Col } from "react-bootstrap";
import "../../staticfiles/css/rw-rule-playground.css";

import xml2js from "xml2js";
const parser = new xml2js.Parser();

const RuleEdit = ({ id, domains, isModal = false, hide }) => {
	let params = useParams();
	id = id || params.id;
	const [uniqueDomains, setUniqueDomains] = useState([]);
	const [dataFetched, setDataFetched] = useState(false);
	const [startDate, setStartDate] = useState("");
	const [endDate, setEndDate] = useState("");
	const [ruleType, setRuleType] = useState("OnceOff");
	const [isGlobal, setGlobal] = useState(false);
	const [priority, setPriority] = useState(1);
	const [selectedDomain, setSelectedDomain] = useState("");
	const [conditionTree, setConditionTree] = useState([
		{ isEmpty: true, val: "", id: 0 },
	]);
	const [ctreeMaxId, setCtreeMaxId] = useState(0);
	const [conditionXML, setConditionXML] = useState("");
	const [commandTable, setCommandTable] = useState([
		{ isEmpty: true, field: "", val: "", isVar: false, id: 0 },
	]);
	const [ctableMaxId, setCtableMaxId] = useState(0);
	const [commandJSON, setCommandJSON] = useState("");
	const [initialXMLTransformed, setXMLTransformed] = useState(false);
	const [initialJSONTransformed, setJSONTransformed] = useState(false);

	const [emailId, setEmailID] = useState("");
	const [testSubject, setTestSubject] = useState("");
	const [testBody, setTestBody] = useState("");
	const [testOutput, setTestOutput] = useState("");
	const [testOutputData, setTestOutputData] = useState("");

	let toRemove = -1;
	let toAdd = false;
	const addOrRemoveCond = (cond) => {
		if (cond === null) return;
		let tmp = editTree(cond, conditionTree);
		setConditionTree(tmp);
	};

	const editTree = (cond, tree) => {
		let tmp = tree.map((node, index) => {
			if (node.id === cond) {
				if (!node.isEmpty) {
					toRemove = index;
				} else {
					toAdd = true;
					return { ...node, isEmpty: false, val: "always-true" };
				}
			} else if (node.children) {
				node.children = editTree(cond, node.children);
			}
			return node;
		});

		if (toRemove !== -1) {
			tmp.splice(toRemove, 1);
			toRemove = -1;
		} else if (toAdd) {
			let id = ctreeMaxId + 1;
			tmp.push({ isEmpty: true, val: "", id: id });
			setCtreeMaxId(id);
			toAdd = false;
		}
		return tmp;
	};

	const treeSelect = (val, cond) => {
		if (cond === null) return;
		let tmp = searchTree(val, cond, conditionTree, treeSelectFn);
		setConditionTree(tmp);
	};

	const containsSelect = (val, cond) => {
		if (cond === null) return;
		let tmp = searchTree(val, cond, conditionTree, containsSelectFn);
		setConditionTree(tmp);
	};

	const containsInput = (val, cond) => {
		if (cond === null) return;
		let tmp = searchTree(val, cond, conditionTree, containsInputFn);
		setConditionTree(tmp);
	};

	const searchTree = (val, cond, tree, action) => {
		return tree.map((node) => {
			if (node.id === cond) {
				return action(node, val);
			}
			if (node.children) {
				node.children = searchTree(val, cond, node.children, action);
			}
			return node;
		});
	};

	const treeSelectFn = (node, val) => {
		if (val === "and" || val === "or") {
			let id = ctreeMaxId + 1;
			setCtreeMaxId(id);
			return {
				...node,
				val: val,
				children: [{ isEmpty: true, val: "", id: id }],
			};
		} else if (val === "not") {
			let id = ctreeMaxId + 1;
			setCtreeMaxId(id);
			return {
				...node,
				val: val,
				children: [{ isEmpty: false, val: "always-true", id: id }],
			};
		} else if (val === "contains") {
			return { ...node, val: val, target: "title", pattern: "" };
		} else {
			delete node.children;
			return { ...node, val: val };
		}
	};

	const containsSelectFn = (node, val) => {
		return { ...node, target: val };
	};

	const containsInputFn = (node, val) => {
		return { ...node, pattern: val };
	};

	const parseConditionToXML = () => {
		let str = conditionToXML(conditionTree, 1);
		str = "<root><and>\n" + str + "</and></root>";
		setConditionXML(str);
		return str;
	};

	const parseXMLtoCondition = () => {
		if (!conditionXML || conditionXML === "") {
			return;
		}
		let parsed = {};
		parser.parseString(conditionXML, function (err, result) {
			parsed = result;
		});
		let tree = [];
		buildCondition(parsed.root.and[0], tree);
		setConditionTree(tree);
		setXMLTransformed(true);
	};

	let localId = ctreeMaxId;
	const buildCondition = (obj, tree, noEmpty = false) => {
		if (Object.keys(obj).length === 0 || !tree) return;
		for (let key in obj) {
			localId += 1;
			if (key === "contains") {
				for (const el of obj[key]) {
					tree.push({
						val: key,
						id: localId++,
						isEmpty: false,
						target: el["$"]["target"],
						pattern: el["$"]["pattern"],
					});
				}
			} else if (key === "and" || key === "or" || key === "not") {
				let child = {
					val: key,
					id: localId,
					isEmpty: false,
					children: [],
				};
				if (key === "not") buildCondition(obj[key][0], child.children, true);
				else {
					buildCondition(obj[key][0], child.children);
				}
				tree.push(child);
			} else {
				tree.push({
					val: key,
					id: localId,
					isEmpty: false,
				});
			}
		}
		if (!noEmpty) tree.push({ isEmpty: true, val: "", id: ++localId });
		setCtreeMaxId(localId);
	};

	const conditionToXML = (tree, depth) => {
		return tree.reduce((prev, el) => {
			if (el.isEmpty) return prev + "";
			if (el.val === "and" || el.val === "or" || el.val === "not") {
				return (
					prev +
					`${"\t".repeat(depth)}` +
					`<${el.val}>\n` +
					conditionToXML(el.children, depth + 1) +
					`${"\t".repeat(depth)}` +
					`</${el.val}>\n`
				);
			} else if (el.val === "contains") {
				let pattern = el.pattern
					.replace(/&/g, "&amp;amp;")
					.replace(/</g, "&lt;")
					.replace(/>/g, "&gt;")
					.replace(/"/g, "&quot;")
					.replace(/'/g, "&apos");
				return (
					prev +
					`${"\t".repeat(depth)}` +
					`<${el.val} target="${el.target}" pattern="${pattern}" />\n`
				);
			} else {
				return prev + `${"\t".repeat(depth)}` + `<${el.val}></${el.val}>\n`;
			}
		}, "");
	};

	const addOrRemoveCommand = (id) => {
		let toRemove = -1;
		let tmp = commandTable.map((cmd, ind) => {
			if (cmd.id === id) {
				if (cmd.isEmpty) {
					return { ...cmd, isEmpty: false, field: "effective_discount" };
				} else {
					toRemove = ind;
				}
			}
			return cmd;
		});
		if (toRemove === -1) {
			const localId = ctableMaxId + 1;
			tmp.push({
				isEmpty: true,
				field: "",
				val: "",
				isVar: false,
				id: localId,
			});
			setCtableMaxId(localId);
		} else {
			tmp.splice(toRemove, 1);
		}
		setCommandTable(tmp);
	};

	const tableFieldSelect = (val, id) => {
		let tmp = commandTable.map((cmd) => {
			if (cmd.id === id) {
				if (
					val === "is_storewide" ||
					val === "is_steep" ||
					val === "is_significant" ||
					val === "is_clearance" ||
					val == "is_steep_threshold" ||
					val == "is_significant_threshold"
				) {
					return { ...cmd, field: val, val: false };
				} else {
					return { ...cmd, field: val, val: "" };
				}
			}
			return cmd;
		});
		setCommandTable(tmp);
	};

	const tableValInput = (val, id) => {
		let tmp = commandTable.map((cmd) => {
			if (cmd.id === id) {
				return { ...cmd, val };
			}
			return cmd;
		});
		setCommandTable(tmp);
	};

	const tableIsVarChange = (id) => {
		let tmp = commandTable.map((cmd) => {
			if (cmd.id === id) {
				return { ...cmd, isVar: !cmd.isVar };
			}
			return cmd;
		});
		setCommandTable(tmp);
	};

	const parseCommandToJSON = () => {
		let obj = { commands: [] };
		obj.commands = commandTable
			.filter((cmd) => !cmd.isEmpty)
			.map((cmd) => {
				return { target: cmd.field, value: cmd.val, is_variable: cmd.isVar };
			});
		let str = JSON.stringify(obj, null, 4);
		setCommandJSON(str);
		return str;
	};

	const parseJSONtoCommand = () => {
		if (!commandJSON || commandJSON === "") {
			return;
		}
		let localId = 0;
		let obj = JSON.parse(commandJSON).commands;
		obj = obj.map((cmd) => {
			return {
				isEmpty: false,
				id: localId++,
				field: cmd.target,
				val: cmd.value,
				isVar: cmd.is_variable,
			};
		});
		obj.push({ isEmpty: true, field: "", val: "", isVar: false, id: localId });
		setCtableMaxId(localId);
		setCommandTable(obj);
		setJSONTransformed(true);
	};

	const renderConditionTree = (tree) => {
		if (!tree) tree = conditionTree;
		return tree.map((cnd) => {
			return (
				<div id={cnd.id} style={{ marginLeft: 50 }}>
					<button onClick={() => addOrRemoveCond(cnd.id)}>
						{cnd.isEmpty ? "+" : "-"}
					</button>
					{cnd.isEmpty ? (
						" ------"
					) : cnd.val === "contains" ? (
						<>
							<select
								className="form-select"
								style={{ marginLeft: 5 }}
								value={cnd.target}
								onChange={(e) => containsSelect(e.target.value, cnd.id)}
							>
								<option value="title">title</option>
								<option value="body">body</option>
							</select>
							<select
								className="form-select"
								style={{ marginLeft: 5 }}
								value={cnd.val}
								onChange={(e) => treeSelect(e.target.value, cnd.id)}
							>
								<option value="always-true">always-true</option>
								<option value="always-false">always-false</option>
								<option value="and">and</option>
								<option value="or">or</option>
								<option value="contains">contains</option>
								<option value="not">not</option>
							</select>
							<input
								type="text"
								style={{ marginLeft: 5 }}
								value={cnd.pattern}
								onChange={(e) => containsInput(e.target.value, cnd.id)}
							></input>
						</>
					) : (
						<>
							<select
								className="form-select"
								style={{ marginLeft: 5 }}
								value={cnd.val}
								onChange={(e) => treeSelect(e.target.value, cnd.id)}
							>
								<option value="always-true">always-true</option>
								<option value="always-false">always-false</option>
								<option value="and">and</option>
								<option value="or">or</option>
								<option value="contains">contains</option>
								<option value="not">not</option>
							</select>
							{cnd.children ? renderConditionTree(cnd.children) : ""}
						</>
					)}
				</div>
			);
		});
	};

	const renderCommandTable = () => {
		return commandTable.map((cmd) => {
			return (
				<tr id={cmd.id}>
					<td>
						<button onClick={() => addOrRemoveCommand(cmd.id)}>
							{cmd.isEmpty ? "+" : "-"}
						</button>
					</td>
					{cmd.isEmpty ? (
						""
					) : (
						<>
							<td>
								<select
									className="form-select"
									style={{ marginLeft: 5 }}
									value={cmd.field}
									onChange={(e) => tableFieldSelect(e.target.value, cmd.id)}
								>
									<option value="min_discount">min_discount</option>
									<option value="max_discount">max_discount</option>
									<option value="effective_discount" selected>
										effective_discount
									</option>
									<option value="state_discount">state_discount</option>
									<option value="product_type">product_type</option>
									<option value="is_storewide">is_storewide</option>
									<option value="is_clearance">is_clearance</option>
									<option value="is_steep">is_steep</option>
									<option value="is_significant">is_significant</option>
									<option value="holiday_tag">holiday_tag</option>
									<option value="free_ship_condition">
										free_ship_condition
									</option>
									<option value="state_discount_val">state_discount_val</option>
									<option value="is_steep_threshold">is_steep_threshold</option>
									<option value="is_signifiant_threshold">
										is_signifiant_threshold
									</option>
								</select>
							</td>
							<td style={{ width: 155 }}>
								{cmd.field === "is_storewide" ||
								cmd.field === "is_steep" ||
								cmd.field === "is_significant" ||
								cmd.field === "is_clearance" ||
								cmd.field === "is_signifiant_threshold" ||
								cmd.field === "is_steep_threshold" ? (
									<select
										className="form-select"
										style={{ marginLeft: 5 }}
										value={cmd.val}
										onChange={(e) =>
											tableValInput(e.target.value === "true", cmd.id)
										}
									>
										<option value="false">False</option>
										<option value="true">True</option>
									</select>
								) : (
									<input
										type="text"
										style={{ marginLeft: 5 }}
										value={cmd.val}
										onChange={(e) => tableValInput(e.target.value, cmd.id)}
									/>
								)}
							</td>
							<td>
								{cmd.field === "is_storewide" ||
								cmd.field === "is_steep" ||
								cmd.field === "is_significant" ||
								cmd.field === "is_clearance" ||
								cmd.field === "is_signifiant_threshold" ||
								cmd.field === "is_steep_threshold" ? (
									""
								) : (
									<input
										type="checkbox"
										style={{ marginLeft: 5 }}
										checked={cmd.isVar}
										onChange={() => tableIsVarChange(cmd.id)}
									/>
								)}
							</td>
						</>
					)}
				</tr>
			);
		});
	};

	const validateCommand = () => {
		commandTable.forEach((cmd) => {
			if (!cmd.isEmpty) {
				if (
					cmd.field === "min_discount" ||
					cmd.field === "max_discount" ||
					cmd.field === "effective_discount" ||
					cmd.field === "state_discount_val"
				) {
					let val = cmd.val;
					if (parseFloat(val) % 1 === 0) {
						toast.warn(cmd.field + " field must be float number");
						return false;
					}
				}
			}
		});
		return true;
	};

	const updateRule = async () => {
		let cnd = conditionXML == "" ? parseConditionToXML() : conditionXML;
		let cmd = commandJSON == "" ? parseCommandToJSON() : commandJSON;
		let data = {
			condition: cnd.replaceAll(/\n|\t/g, ""),
			command: cmd.replaceAll(/\s|\n|\\\//g, ""),
			priority: parseInt(priority),
			type: ruleType,
			is_global: isGlobal,
		};
		if (ruleType === "OnceOff") {
			data["start_date"] = startDate;
			data["end_date"] = endDate;
		}
		if (!isGlobal) {
			data["domain"] = selectedDomain;
		} else {
			data["domain"] = "";
		}

		await axios
			.post(
				getLunaUrl(`v1/system-discount/rules/update/?rule_id=${id}`),
				data,
				getNumericalHeader()
			)
			.then((res) => {
				toast.success("Rule updated successfully");
			})
			.catch((err) => toast.error("An error occurred, please try again"));
	};

	const loadEmail = async () => {
		if (!emailId || emailId === "") {
			toast.warn("Please fill in a valid email ID");
			return;
		}

		await axios
			.get(getLunaUrl(`v1/emailfeed/data/${emailId}/`), getNumericalHeader())
			.then((res) => {
				const data = res.data.email_detail;
				toast.success("Email loaded successfully");
				setTestSubject(data.subject || "");
				setTestBody(data.message?.text_annotations || "");
			})
			.catch((err) => {});
	};

	const checkEmailOutput = async () => {
		const payload = {
			title: testSubject,
			body: testBody,
			xml: conditionXML,
			command: commandJSON,
		};
		await axios
			.post(
				getLunaUrl(`v1/system-discount/exec/`),
				payload,
				getNumericalHeader()
			)
			.then((res) => {
				const result = res.data.result;
				if (result) {
					toast.success("Email matched");
				} else {
					toast.error("No match found");
				}
				setTestOutput(JSON.stringify(res.data.discount, null, 2));
				setTestOutputData(JSON.stringify(res.data.data, null, 2));
			})
			.catch((err) => {
				toast.error(
					"Failed to load output, please try again or contact the administrator"
				);
			});
	};

	useEffect(() => {
		// if (!loaded) return;
		const getRuleDetails = async () => {
			await axios
				.get(
					getLunaUrl(`v1/system-discount/rules/?rule_id=${id}`),
					getNumericalHeader()
				)
				.then((res) => {
					const data = res.data.data[0];
					setStartDate(data.start_date);
					setEndDate(data.end_date);
					setConditionXML(data.condition);
					setCommandJSON(data.command);
					setPriority(parseInt(data.priority));
					setGlobal(data.is_global);
					setSelectedDomain(data.company?.domain || "");
					setRuleType(data.type);
				})
				.catch((err) => {});
		};
		const fetchDomains = async () => {
			let allDomains = [];
			await axios
				.get(
					getLunaUrl("v1/emailfeed/company/?page_size=1000"),
					getNumericalHeader()
				)
				.then(async (response) => {
					for (let i = 0; i < response.data.results.length; i++) {
						let tempData = response.data.results[i];
						allDomains.push({
							name: tempData.name,
							domain: tempData.domain,
							parent_domain: tempData.parent_domain,
							id: tempData.id,
							ticker: tempData.ticker,
							sim_category: tempData.sim_category,
						});
					}

					if (response.data.next !== null) {
						await getNextPageDomains(
							processNextpageUrl(response.data.next),
							allDomains
						);
					}
					setUniqueDomains(allDomains);
					setDataFetched(true);
				})
				.catch((error) => {
					console.log(error);
				});
		};
		const getNextPageDomains = async (url, allDomains) => {
			await axios
				.get(url, getNumericalHeader())
				.then(async (response) => {
					for (let i = 0; i < response.data.results.length; i++) {
						let tempData = response.data.results[i];
						allDomains.push({
							name: tempData.name,
							domain: tempData.domain,
							id: tempData.id,
							ticker: tempData.ticker,
							parent_domain: tempData.parent_domain,
							sim_category: tempData.sim_category,
						});
					}
					if (response.data.next !== null) {
						await getNextPageDomains(
							processNextpageUrl(response.data.next),
							allDomains
						);
					}
				})
				.catch((error) => {
					console.log(error);
				});
		};
		if (!domains) {
			fetchDomains();
		} else {
			setUniqueDomains(domains);
			setDataFetched(true);
		}
		getRuleDetails();
	}, []);

	useEffect(() => {
		if (initialXMLTransformed) return;
		parseXMLtoCondition();
	}, [conditionXML]);

	useEffect(() => {
		if (initialJSONTransformed) return;
		parseJSONtoCommand();
	}, [commandJSON]);

	if (!dataFetched) {
		return (
			<div className="spinner-wrapper">
				<div className="spinner"></div>
			</div>
		);
	}
	if (isModal) {
		return (
			<>
				<span>
					<h4 className="page-title">Editing Rule {id}</h4>
					<div className="close-btn" onClick={hide}>
						X
					</div>
					<div className="mytooltip2">
						<Link
							to={`/rule-edit/${id}`}
							target="_blank"
							rel="noopener noreferrer"
							className="mytooltip-target"
						>
							<i className="bi bi-aspect-ratio export-icon"></i>
						</Link>
						<div className="tooltiptext">Open in a separate window</div>
					</div>
				</span>
				<div>
					<h3>Edit</h3>
					{isGlobal ? (
						""
					) : (
						<Row className="rule-creation-row">
							<Col sm={12} md={4} className="rule-creation-title">
								Domain:
							</Col>

							<Col sm={12} md={4}>
								<select
									value={selectedDomain}
									onChange={(e) => setSelectedDomain(e.target.value)}
								>
									<option value="" selected disabled>
										Please select a domain
									</option>
									{uniqueDomains
										.filter((domain) => domain.domain === domain.parent_domain)
										.map((domain) => {
											return (
												<option key={domain.id} value={domain.domain}>
													{domain.domain}
												</option>
											);
										})}
								</select>
							</Col>
						</Row>
					)}

					<Row className="rule-creation-row">
						<Col sm={12} md={4} className="rule-creation-title mytooltip">
							Type:&nbsp;
							<i class="dripicons-information mytooltip-target"></i> :
							<div className="tooltiptext">
								OneTime job only impacts the data between the start date and the
								end date. For other types, the data range doesn't apply.
							</div>
						</Col>

						<Col sm={12} md={4}>
							<select
								className="form-select"
								value={ruleType}
								onChange={(e) => setRuleType(e.target.value)}
							>
								<option value="OnceOff">One Time</option>
								<option value="Weekly">Weekly</option>
								<option value="Monthly">Monthly</option>
							</select>
						</Col>
					</Row>

					{ruleType === "OnceOff" ? (
						<>
							<Row className="rule-creation-row">
								<Col sm={12} md={4} className="rule-creation-title">
									Start Date:
								</Col>

								<Col sm={12} md={4}>
									<input
										type="date"
										value={startDate}
										onChange={(e) => setStartDate(e.target.value)}
									></input>
								</Col>
							</Row>

							<Row className="rule-creation-row">
								<Col sm={12} md={4} className="rule-creation-title">
									End Date:
								</Col>

								<Col sm={12} md={4}>
									<input
										type="date"
										value={endDate}
										onChange={(e) => setEndDate(e.target.value)}
									></input>
								</Col>
							</Row>
						</>
					) : (
						""
					)}

					<Row className="rule-creation-row">
						<Col sm={12} md={4} className="rule-creation-title mytooltip">
							Apply Globally:&nbsp;
							<i class="dripicons-information mytooltip-target"></i> :
							<div className="tooltiptext">
								If this rule will apply to all the domains.
							</div>
						</Col>

						<Col sm={12} md={4}>
							<div class="form-check">
								<CustomInput
									type="switch"
									id="isGlobalSwitch"
									checked={isGlobal}
									onClick={(e) => setGlobal(e.target.checked)}
								/>
							</div>
						</Col>
					</Row>

					<Row className="rule-creation-row">
						<Col sm={12} md={4} className="rule-creation-title mytooltip">
							Priority:&nbsp;
							<i class="dripicons-information mytooltip-target"></i> :
							<div className="tooltiptext">
								Higher priority will override the result of lower priority.
							</div>
						</Col>

						<Col sm={12} md={4}>
							<input
								type="number"
								min="1"
								value={priority}
								onChange={(e) => setPriority(e.target.value)}
							/>
						</Col>
					</Row>

					<Row className="rule-creation-row">
						<Col sm={12} className="rule-creation-title">
							Condition(Tree):
						</Col>
					</Row>

					<Row className="rule-creation-row">
						<Col sm={12}>
							<div>root(and):</div>
							{renderConditionTree()}
						</Col>
					</Row>

					<Row className="rule-creation-row">
						<Col sm={12}>
							<button onClick={parseConditionToXML}>ToXML</button>
							<button onClick={parseXMLtoCondition}>FromXML</button>
						</Col>
					</Row>

					<Row className="rule-creation-row">
						<Col sm={12} className="rule-creation-title">
							Condition(XML):
						</Col>
					</Row>

					<Row className="rule-creation-row">
						<Col sm={12} style={{ marginTop: 5 }}>
							<textarea
								id="condition-xml-textarea"
								class="form-control"
								style={{ height: 120 }}
								spellcheck="false"
								value={conditionXML}
								onChange={(e) => setConditionXML(e.target.value)}
							></textarea>
						</Col>
					</Row>

					<Row className="rule-creation-row">
						<Col sm={12} className="rule-creation-title">
							Command(Table):
						</Col>
						<Col sm={12}>
							<table>
								<thead>
									<tr>
										<td></td>
										<td>&nbsp;&nbsp;Field</td>
										<td>&nbsp;&nbsp;Value</td>
										<td>&nbsp;&nbsp;Is Variable</td>
									</tr>
								</thead>
								<tbody>{renderCommandTable()}</tbody>
							</table>
						</Col>
					</Row>

					<Row className="rule-creation-row">
						<Col sm={12}>
							<button onClick={parseCommandToJSON}>ToJSON</button>
							<button onClick={parseJSONtoCommand}>FromJSON</button>
						</Col>
					</Row>

					<Row className="rule-creation-row">
						<Col sm={12} className="rule-creation-title">
							Command(JSON):
						</Col>

						<Col sm={12} style={{ marginTop: 5 }}>
							<textarea
								id="command-json-textarea"
								class="form-control"
								style={{ height: 100 }}
								spellcheck="false"
								value={commandJSON}
								onChange={(e) => setCommandJSON(e.target.value)}
							></textarea>
						</Col>
					</Row>

					<Row style={{ marginTop: 50 }} className="rule-creation-row">
						<Col sm={12} className="rule-creation-title">
							<button
								class="btn btn-outline-primary rule-btns"
								onClick={updateRule}
							>
								Add To Database
							</button>
						</Col>
					</Row>
					<br />
					<br />
					<h3>Test</h3>
					<Row className="rule-creation-row">
						<Col md={4} className="rule-creation-title">
							Load Email By ID:
						</Col>

						<input
							type="text"
							value={emailId}
							onChange={(e) => setEmailID(e.target.value)}
							style={{ marginRight: 10 }}
						/>
						<button onClick={loadEmail}>Load Email</button>
					</Row>

					<Row className="rule-creation-row">
						<Col sm={12} className="rule-creation-title">
							Subject:
						</Col>

						<Col sm={12} style={{ marginTop: 5 }}>
							<textarea
								class="form-control"
								style={{ height: 100 }}
								spellcheck="false"
								value={testSubject}
								onChange={(e) => setTestSubject(e.target.value)}
							></textarea>
						</Col>
					</Row>

					<Row className="rule-creation-row">
						<Col sm={12} className="rule-creation-title">
							Body:
						</Col>

						<Col sm={12} style={{ marginTop: 5 }}>
							<textarea
								class="form-control"
								style={{ height: 100 }}
								spellcheck="false"
								value={testBody}
								onChange={(e) => setTestBody(e.target.value)}
							></textarea>
						</Col>
					</Row>

					<Row style={{ marginTop: 50 }} className="rule-creation-row">
						<Col sm={12} className="rule-creation-title">
							<button
								class="btn btn-outline-primary rule-btns"
								onClick={checkEmailOutput}
							>
								Check Output
							</button>
						</Col>
					</Row>

					<Row className="rule-creation-row">
						<Col sm={12} className="rule-creation-title">
							Results:
						</Col>

						<Col>{testOutput}</Col>
					</Row>

					<Row className="rule-creation-row">
						<Col sm={12} className="rule-creation-title">
							Variables:
						</Col>

						<Col>{testOutputData}</Col>
					</Row>
				</div>
			</>
		);
	} else {
		return (
			<>
				<span>
					<h4 className="page-title">Editing Rule {id}</h4>
				</span>
				<div style={{ display: "flex" }}>
					<div style={{ width: "45%", marginRight: "10%" }}>
						<h3>Edit</h3>
						{isGlobal ? (
							""
						) : (
							<Row className="rule-creation-row">
								<Col sm={12} md={4} className="rule-creation-title">
									Domain:
								</Col>

								<Col sm={12} md={4}>
									<select
										value={selectedDomain}
										onChange={(e) => setSelectedDomain(e.target.value)}
									>
										<option value="" selected disabled>
											Please select a domain
										</option>
										{uniqueDomains
											.filter(
												(domain) => domain.domain === domain.parent_domain
											)
											.map((domain) => {
												return (
													<option key={domain.id} value={domain.domain}>
														{domain.domain}
													</option>
												);
											})}
									</select>
								</Col>
							</Row>
						)}

						<Row className="rule-creation-row">
							<Col sm={12} md={4} className="rule-creation-title mytooltip">
								Type:&nbsp;
								<i class="dripicons-information mytooltip-target"></i> :
								<div className="tooltiptext">
									OneTime job only impacts the data between the start date and
									the end date. For other types, the data range doesn't apply.
								</div>
							</Col>

							<Col sm={12} md={4}>
								<select
									className="form-select"
									value={ruleType}
									onChange={(e) => setRuleType(e.target.value)}
								>
									<option value="OnceOff">One Time</option>
									<option value="Weekly">Weekly</option>
									<option value="Monthly">Monthly</option>
								</select>
							</Col>
						</Row>

						{ruleType === "OnceOff" ? (
							<>
								<Row className="rule-creation-row">
									<Col sm={12} md={4} className="rule-creation-title">
										Start Date:
									</Col>

									<Col sm={12} md={4}>
										<input
											type="date"
											value={startDate}
											onChange={(e) => setStartDate(e.target.value)}
										></input>
									</Col>
								</Row>

								<Row className="rule-creation-row">
									<Col sm={12} md={4} className="rule-creation-title">
										End Date:
									</Col>

									<Col sm={12} md={4}>
										<input
											type="date"
											value={endDate}
											onChange={(e) => setEndDate(e.target.value)}
										></input>
									</Col>
								</Row>
							</>
						) : (
							""
						)}

						<Row className="rule-creation-row">
							<Col sm={12} md={4} className="rule-creation-title mytooltip">
								Apply Globally:&nbsp;
								<i class="dripicons-information mytooltip-target"></i> :
								<div className="tooltiptext">
									If this rule will apply to all the domains.
								</div>
							</Col>

							<Col sm={12} md={4}>
								<div class="form-check">
									<CustomInput
										type="switch"
										id="isGlobalSwitch"
										checked={isGlobal}
										onClick={(e) => setGlobal(e.target.checked)}
									/>
								</div>
							</Col>
						</Row>

						<Row className="rule-creation-row">
							<Col sm={12} md={4} className="rule-creation-title mytooltip">
								Priority:&nbsp;
								<i class="dripicons-information mytooltip-target"></i> :
								<div className="tooltiptext">
									Higher priority will override the result of lower priority.
								</div>
							</Col>

							<Col sm={12} md={4}>
								<input
									type="number"
									min="1"
									value={priority}
									onChange={(e) => setPriority(e.target.value)}
								/>
							</Col>
						</Row>

						<Row className="rule-creation-row">
							<Col sm={12} className="rule-creation-title">
								Condition(Tree):
							</Col>
						</Row>

						<Row className="rule-creation-row">
							<Col sm={12}>
								<div>root(and):</div>
								{renderConditionTree()}
							</Col>
						</Row>

						<Row className="rule-creation-row">
							<Col sm={12}>
								<button onClick={parseConditionToXML}>ToXML</button>
								<button onClick={parseXMLtoCondition}>FromXML</button>
							</Col>
						</Row>

						<Row className="rule-creation-row">
							<Col sm={12} className="rule-creation-title">
								Condition(XML):
							</Col>
						</Row>

						<Row className="rule-creation-row">
							<Col sm={12} style={{ marginTop: 5 }}>
								<textarea
									id="condition-xml-textarea"
									class="form-control"
									style={{ height: 120 }}
									spellcheck="false"
									value={conditionXML}
									onChange={(e) => setConditionXML(e.target.value)}
								></textarea>
							</Col>
						</Row>

						<Row className="rule-creation-row">
							<Col sm={12} className="rule-creation-title">
								Command(Table):
							</Col>
							<Col sm={12}>
								<table>
									<thead>
										<tr>
											<td></td>
											<td>&nbsp;&nbsp;Field</td>
											<td>&nbsp;&nbsp;Value</td>
											<td>&nbsp;&nbsp;Is Variable</td>
										</tr>
									</thead>
									<tbody>{renderCommandTable()}</tbody>
								</table>
							</Col>
						</Row>

						<Row className="rule-creation-row">
							<Col sm={12}>
								<button onClick={parseCommandToJSON}>ToJSON</button>
								<button onClick={parseJSONtoCommand}>FromJSON</button>
							</Col>
						</Row>

						<Row className="rule-creation-row">
							<Col sm={12} className="rule-creation-title">
								Command(JSON):
							</Col>

							<Col sm={12} style={{ marginTop: 5 }}>
								<textarea
									id="command-json-textarea"
									class="form-control"
									style={{ height: 100 }}
									spellcheck="false"
									value={commandJSON}
									onChange={(e) => setCommandJSON(e.target.value)}
								></textarea>
							</Col>
						</Row>

						<Row style={{ marginTop: 50 }} className="rule-creation-row">
							<Col sm={12} className="rule-creation-title">
								<button
									class="btn btn-outline-primary rule-btns"
									onClick={updateRule}
								>
									Add To Database
								</button>
							</Col>
						</Row>
					</div>
					<div>
						<h3>Test</h3>
						<Row className="rule-creation-row">
							<Col md={4} className="rule-creation-title">
								Load Email By ID:
							</Col>

							<input
								type="text"
								value={emailId}
								onChange={(e) => setEmailID(e.target.value)}
								style={{ marginRight: 10 }}
							/>
							<button onClick={loadEmail}>Load Email</button>
						</Row>

						<Row className="rule-creation-row">
							<Col sm={12} className="rule-creation-title">
								Subject:
							</Col>

							<Col sm={12} style={{ marginTop: 5 }}>
								<textarea
									class="form-control"
									style={{ height: 100 }}
									spellcheck="false"
									value={testSubject}
									onChange={(e) => setTestSubject(e.target.value)}
								></textarea>
							</Col>
						</Row>

						<Row className="rule-creation-row">
							<Col sm={12} className="rule-creation-title">
								Body:
							</Col>

							<Col sm={12} style={{ marginTop: 5 }}>
								<textarea
									class="form-control"
									style={{ height: 100 }}
									spellcheck="false"
									value={testBody}
									onChange={(e) => setTestBody(e.target.value)}
								></textarea>
							</Col>
						</Row>

						<Row style={{ marginTop: 50 }} className="rule-creation-row">
							<Col sm={12} className="rule-creation-title">
								<button
									class="btn btn-outline-primary rule-btns"
									onClick={checkEmailOutput}
								>
									Check Output
								</button>
							</Col>
						</Row>

						<Row className="rule-creation-row">
							<Col sm={12} className="rule-creation-title">
								Results:
							</Col>

							<Col>{testOutput}</Col>
						</Row>

						<Row className="rule-creation-row">
							<Col sm={12} className="rule-creation-title">
								Variables:
							</Col>

							<Col>{testOutputData}</Col>
						</Row>
					</div>
				</div>
			</>
		);
	}
};

export default RuleEdit;
