import React, { useState, useEffect } from "react";
import { CustomInput } from "reactstrap";
import { Row, Col, Dropdown, Modal, Card } from "react-bootstrap";
import { toast } from "react-toastify";
import {
	CustomToggle,
	CustomMenu,
} from "../../ReusableComponents/TypableDropdown";
import "../../staticfiles/css/rw-rule-playground.css";
import {
	getLunaUrl,
	getNumericalHeader,
	formatCategoryString,
	axios,
} from "../utils";
import RuleTable from "./RuleTable";
import DeepDiscountTable from "./DeepDiscountTable";
import RegularExpressionRules from "./RegularExpressionRules";
import RuleEdit from "./RuleEdit";

import xml2js from "xml2js";
const parser = new xml2js.Parser();

const DiscountTable = ({ uniqueDomains, tickers, loaded }) => {
	const [domains, setDomains] = useState([]);
	const [loadingDiscount, setLoadingDiscount] = useState(false);
	const [loadingRule, setLoadingRule] = useState(false);

	const [minStartDate, setminStartDate] = useState("");
	const [maxStartDate, setmaxStartDate] = useState("");
	const [startDate, setStartDate] = useState("");
	const [tempStartDate, setTempStartDate] = useState("");

	const [minEndDate, setminEndDate] = useState("");
	const [maxEndDate, setmaxEndDate] = useState("");
	const [endDate, setEndDate] = useState("");
	const [tempEndDate, setTempEndDate] = useState("");

	const [discountData, setDiscountData] = useState([]);
	const [displayData, setDisplayData] = useState([]);
	const [appliedData, setAppliedData] = useState([]);
	const [ruleData, setRuleData] = useState([]);
	const [selectedDomain, setSelectedDomain] = useState({});
	const [selectedTicker, setSelectedTicker] = useState("");
	const [selectedTickerDomains, setSelectedTickerDomains] = useState([]);

	const [ruleCreation, setRuleCreation] = useState(false);
	const [ruleTypeCreate, setRuleTypeCreate] = useState("OnceOff");
	const [isGlobalCreate, setGlobalCreate] = useState(false);
	const [priorityCreate, setPriorityCreate] = useState(1);
	const [startDateCreate, setStartDateCreate] = useState("");
	const [endDateCreate, setEndDateCreate] = useState("");
	const [conditionTree, setConditionTree] = useState([
		{ isEmpty: true, val: "", id: 0 },
	]);
	const [ctreeMaxId, setCtreeMaxId] = useState(0);
	const [conditionXML, setConditionXML] = useState("");
	const [commandTable, setCommandTable] = useState([
		{ isEmpty: true, field: "", val: "", isVar: false, id: 0 },
	]);
	const [ctableMaxId, setCtableMaxId] = useState(0);
	const [commandJSON, setCommandJSON] = useState("");

	const [emailId, setEmailID] = useState("");
	const [testSubject, setTestSubject] = useState("");
	const [testBody, setTestBody] = useState("");
	const [testOutput, setTestOutput] = useState("");
	const [testOutputData, setTestOutputData] = useState("");

	const [ruleEdit, setRuleEdit] = useState(false);
	const [idEdit, setIdEdit] = useState("");

	const loadDiscountData = async () => {
		setLoadingDiscount(true);
		try {
			let discountResponse = await axios.get(
				getLunaUrl(
					`v2/deep-discount/?domain=${selectedDomain.domain}&weekDate=${endDate}&startDate=${startDate}&group=true`
				),
				getNumericalHeader()
			);
			let totalData = [];
			for (let res of discountResponse.data.data) {
				let cate = formatCategoryString(res.email_detail__category);
				totalData.push({
					subject: res.email_detail__subject,
					date: res.email_detail__end_date,
					volume: res.project__total_volume,
					group_id: res.group_id,
					min: res.deep_discount__min_discount_pct,
					max: res.deep_discount__max_discount_pct,
					effectivePct: res.deep_discount__effective_discount_pct,
					state: res.deep_discount__state_discount,
					product: res.deep_discount__product_type,
					category: cate,
					state_value: res.deep_discount__state_discount_val,
					storewide: res.deep_discount__is_storewide,
					holiday: res.deep_discount__holiday_tag,
					freeShip: res.deep_discount__free_ship_condition,
					feed_id: res.feed_id,
					last_edited: res.deep_discount__in_dt,
					editor: res.deep_discount__editor,
				});
			}
			setDiscountData(totalData);
			setDisplayData(totalData);
		} catch (err) {
			console.log(err);
		}
		setLoadingDiscount(false);
	};

	const setInitialEndDate = () => {
		let curTime = new Date();

		var year = curTime.getFullYear();
		var month = curTime.getMonth();
		var day = curTime.getDate();
		var c = new Date(year, month, day);

		month = "" + (c.getMonth() + 1);
		day = "" + c.getDate();
		year = c.getFullYear();

		if (month.length < 2) month = "0" + month;
		if (day.length < 2) day = "0" + day;

		curTime = [year, month, day].join("-");

		setEndDate(curTime);
		setTempEndDate(curTime);
	};

	const setInitialStartDate = () => {
		let curTime = new Date();

		var year = curTime.getFullYear();
		var month = curTime.getMonth();
		var day = curTime.getDate();
		var c = new Date(year, month - 2, day);

		month = "" + (c.getMonth() + 1);
		day = "" + c.getDate();
		year = c.getFullYear();

		if (month.length < 2) month = "0" + month;
		if (day.length < 2) day = "0" + day;

		curTime = [year, month, day].join("-");

		setStartDate(curTime);
		setTempStartDate(curTime);
	};

	const applyRule = async () => {
		setLoadingDiscount(true);
		try {
			let ruleResponse = await axios.get(
				getLunaUrl(`v2/system-discount/apply/?domain=${selectedDomain.domain}&
				weekDate=${endDate}&startDate=${startDate}`),
				getNumericalHeader()
			);
			let res = ruleResponse.data;
			let applied = [];
			let totalData = discountData;
			for (let r of res.data) {
				let tmp = {
					subject: r.email_detail__subject,
					date: r.email_detail__end_date,
					volume: r.project__total_volume,
					group_id: r.group_id,
					min: r.deep_discount__min_discount_pct,
					max: r.deep_discount__max_discount_pct,
					effectivePct: r.deep_discount__effective_discount_pct,
					state: r.deep_discount__state_discount,
					product: r.deep_discount__product_type,
					storewide: r.deep_discount__is_storewide,
					holiday: r.deep_discount__holiday_tag,
					freeShip: r.deep_discount__free_ship_condition,
					feed_id: r.feed_id,
					last_edited: r.deep_discount__in_dt,
					editor: r.deep_discount__editor,
				};
				applied.push(tmp);
				let altered = discountData.findIndex(
					(elem) => elem.feed_id === r.feed_id
				);
				if (altered) {
					totalData[altered] = tmp;
				}
			}
			setAppliedData(applied);
			setDisplayData(totalData);
			setLoadingDiscount(false);
			let str = `Success:
			${res.applied} applied
			${res.emails_affeced} emails affected
			among ${res.email_matched} email matched`;
			toast.success(str);
		} catch (err) {
			console.log(err);
		}
	};

	const showApplied = () => {
		setDisplayData(appliedData);
	};

	const loadRule = async () => {
		setLoadingRule(true);
		try {
			let ruleResponse = await axios.get(
				getLunaUrl(`v1/system-discount/rules/?domain=${selectedDomain.domain}`),
				getNumericalHeader()
			);
			let totalData = [];
			for (const rule of ruleResponse.data.data) {
				let parsedCond = rule.condition;
				parser.parseString(rule.condition, function (err, result) {
					parsedCond = result;
				});
				let tree = { name: "root", children: [] };
				if (parsedCond) {
					buildTree(parsedCond.root, tree);
				}
				totalData.push({ ...rule, parsedCondition: tree });
			}
			setRuleData(totalData);
		} catch (err) {
			console.log(err);
		}
		setLoadingRule(false);
	};

	const buildTree = (obj, tree) => {
		if (Object.keys(obj).length === 0) return;
		if (!tree) return;
		for (let key in obj) {
			if (key == "$") {
				tree.children.push({
					name: "target",
					children: [{ name: obj[key]["target"] }],
				});
				tree.children.push({
					name: "pattern",
					children: [{ name: obj[key]["pattern"] }],
				});
			} else if (key == "contains") {
				for (let i = 0; i < obj[key].length; i++) {
					tree.children.push({
						name: key,
						children: [],
					});
				}
			} else {
				tree.children.push({
					name: key,
					children: [],
				});
			}
			let tmp = obj[key] instanceof Array ? obj[key] : [];
			tmp.forEach((child, index) => buildTree(child, tree.children[index]));
		}
	};

	let localId = ctreeMaxId;
	const buildCondition = (obj, tree, noEmpty = false) => {
		if (Object.keys(obj).length === 0 || !tree) return;
		for (let key in obj) {
			localId += 1;
			if (key === "contains") {
				for (const el of obj[key]) {
					tree.push({
						val: key,
						id: localId++,
						isEmpty: false,
						target: el["$"]["target"],
						pattern: el["$"]["pattern"],
					});
				}
			} else if (key === "and" || key === "or" || key === "not") {
				let child = {
					val: key,
					id: localId,
					isEmpty: false,
					children: [],
				};
				if (key === "not") buildCondition(obj[key][0], child.children, true);
				else {
					buildCondition(obj[key][0], child.children);
				}
				tree.push(child);
			} else {
				tree.push({
					val: key,
					id: localId,
					isEmpty: false,
				});
			}
		}
		if (!noEmpty) tree.push({ isEmpty: true, val: "", id: ++localId });
		setCtreeMaxId(localId);
	};

	const deleteRule = async (rule_id) => {
		if (window.confirm("Are you sure that you want to delete this rule?")) {
			try {
				let deleteRes = await axios.get(
					getLunaUrl(`v1/system-discount/rules/delete/?rule_id=${rule_id}`),
					getNumericalHeader()
				);
				loadRule();
			} catch (err) {
				console.log(err);
			}
		}
	};

	const changeStartDate = () => {
		let newDate = tempStartDate;

		if (new Date(newDate) > new Date(endDate)) {
			toast.warn("you cannot set a start date that is later than the end date");
			setTempStartDate(startDate);
			return;
		}

		if (new Date(newDate) < new Date(minStartDate)) {
			toast.warn("the earlies start date is: " + minStartDate);
			setTempStartDate(minStartDate);
			setStartDate(minStartDate);
			return;
		}

		if (new Date(newDate) > new Date(maxStartDate)) {
			toast.warn("the latest start date is: " + maxStartDate);
			setTempStartDate(maxStartDate);
			setStartDate(maxStartDate);
			return;
		}

		setStartDate(newDate);
	};

	const changeEndDate = () => {
		let newDate = tempEndDate;

		if (new Date(newDate) < new Date(startDate)) {
			toast.warn(
				"you cannot set an end date that is earlier than the start date"
			);
			setTempEndDate(endDate);
			return;
		}

		if (new Date(newDate) < new Date(minEndDate)) {
			toast.warn("the earlies end date is: " + minEndDate);
			setEndDate(minEndDate);
			setTempEndDate(minEndDate);
			return;
		}

		if (new Date(newDate) > new Date(maxEndDate)) {
			toast.warn("the latest end date is: " + maxEndDate);
			setEndDate(maxEndDate);
			setTempEndDate(maxEndDate);
			return;
		}

		setEndDate(newDate);
	};

	let toRemove = -1;
	let toAdd = false;
	const addOrRemoveCond = (cond) => {
		if (cond === null) return;
		let tmp = editTree(cond, conditionTree);
		setConditionTree(tmp);
	};

	const editTree = (cond, tree) => {
		let tmp = tree.map((node, index) => {
			if (node.id === cond) {
				if (!node.isEmpty) {
					toRemove = index;
				} else {
					toAdd = true;
					return { ...node, isEmpty: false, val: "always-true" };
				}
			} else if (node.children) {
				node.children = editTree(cond, node.children);
			}
			return node;
		});

		if (toRemove !== -1) {
			tmp.splice(toRemove, 1);
			toRemove = -1;
		} else if (toAdd) {
			let id = ctreeMaxId + 1;
			tmp.push({ isEmpty: true, val: "", id: id });
			setCtreeMaxId(id);
			toAdd = false;
		}
		return tmp;
	};

	const treeSelect = (val, cond) => {
		if (cond === null) return;
		let tmp = searchTree(val, cond, conditionTree, treeSelectFn);
		setConditionTree(tmp);
	};

	const containsSelect = (val, cond) => {
		if (cond === null) return;
		let tmp = searchTree(val, cond, conditionTree, containsSelectFn);
		setConditionTree(tmp);
	};

	const containsInput = (val, cond) => {
		if (cond === null) return;
		let tmp = searchTree(val, cond, conditionTree, containsInputFn);
		setConditionTree(tmp);
	};

	const searchTree = (val, cond, tree, action) => {
		return tree.map((node) => {
			if (node.id === cond) {
				return action(node, val);
			}
			if (node.children) {
				node.children = searchTree(val, cond, node.children, action);
			}
			return node;
		});
	};

	const treeSelectFn = (node, val) => {
		if (val === "and" || val === "or") {
			let id = ctreeMaxId + 1;
			setCtreeMaxId(id);
			return {
				...node,
				val: val,
				children: [{ isEmpty: true, val: "", id: id }],
			};
		} else if (val === "not") {
			let id = ctreeMaxId + 1;
			setCtreeMaxId(id);
			return {
				...node,
				val: val,
				children: [{ isEmpty: false, val: "always-true", id: id }],
			};
		} else if (val === "contains") {
			return { ...node, val: val, target: "title", pattern: "" };
		} else {
			delete node.children;
			return { ...node, val: val };
		}
	};

	const containsSelectFn = (node, val) => {
		return { ...node, target: val };
	};

	const containsInputFn = (node, val) => {
		return { ...node, pattern: val };
	};

	const parseConditionToXML = () => {
		let str = conditionToXML(conditionTree, 1);
		str = "<root><and>\n" + str + "</and></root>";
		setConditionXML(str);
		return str;
	};

	const parseXMLtoCondition = () => {
		let parsed = {};
		parser.parseString(conditionXML, function (err, result) {
			parsed = result;
		});
		let tree = [];
		buildCondition(parsed.root.and[0], tree);
		setConditionTree(tree);
	};

	const conditionToXML = (tree, depth) => {
		return tree.reduce((prev, el) => {
			if (el.isEmpty) return prev + "";
			if (el.val === "and" || el.val === "or" || el.val === "not") {
				return (
					prev +
					`${"\t".repeat(depth)}` +
					`<${el.val}>\n` +
					conditionToXML(el.children, depth + 1) +
					`${"\t".repeat(depth)}` +
					`</${el.val}>\n`
				);
			} else if (el.val === "contains") {
				let pattern = el.pattern
					.replace(/&/g, "&amp;amp;")
					.replace(/</g, "&lt;")
					.replace(/>/g, "&gt;")
					.replace(/"/g, "&quot;")
					.replace(/'/g, "&apos");
				return (
					prev +
					`${"\t".repeat(depth)}` +
					`<${el.val} target="${el.target}" pattern="${pattern}" />\n`
				);
			} else {
				return prev + `${"\t".repeat(depth)}` + `<${el.val}></${el.val}>\n`;
			}
		}, "");
	};

	const addOrRemoveCommand = (id) => {
		let toRemove = -1;
		let tmp = commandTable.map((cmd, ind) => {
			if (cmd.id === id) {
				if (cmd.isEmpty) {
					return { ...cmd, isEmpty: false, field: "effective_discount" };
				} else {
					toRemove = ind;
				}
			}
			return cmd;
		});
		if (toRemove === -1) {
			const localId = ctableMaxId + 1;
			tmp.push({
				isEmpty: true,
				field: "",
				val: "",
				isVar: false,
				id: localId,
			});
			setCtableMaxId(localId);
		} else {
			tmp.splice(toRemove, 1);
		}
		setCommandTable(tmp);
	};

	const tableFieldSelect = (val, id) => {
		let tmp = commandTable.map((cmd) => {
			if (cmd.id === id) {
				if (
					val === "is_storewide" ||
					val === "is_steep" ||
					val === "is_significant" ||
					val === "is_clearance" ||
					val == "is_steep_threshold" ||
					val == "is_significant_threshold"
				) {
					return { ...cmd, field: val, val: false };
				}
				return { ...cmd, field: val };
			}
			return cmd;
		});
		setCommandTable(tmp);
	};

	const tableValInput = (val, id) => {
		let tmp = commandTable.map((cmd) => {
			if (cmd.id === id) {
				return { ...cmd, val: val };
			}
			return cmd;
		});
		setCommandTable(tmp);
	};

	const tableIsVarChange = (id) => {
		let tmp = commandTable.map((cmd) => {
			if (cmd.id === id) {
				return { ...cmd, isVar: !cmd.isVar };
			}
			return cmd;
		});
		setCommandTable(tmp);
	};

	const parseCommandToJSON = () => {
		let obj = { commands: [] };
		obj.commands = commandTable
			.filter((cmd) => !cmd.isEmpty)
			.map((cmd) => {
				return { target: cmd.field, value: cmd.val, is_variable: cmd.isVar };
			});
		let str = JSON.stringify(obj, null, 4);
		setCommandJSON(str);
		return str;
	};

	const parseJSONtoCommand = () => {
		let localId = 0;
		let obj = JSON.parse(commandJSON).commands;
		obj = obj.map((cmd) => {
			return {
				isEmpty: false,
				id: localId++,
				field: cmd.target,
				val: cmd.value,
				isVar: cmd.is_variable,
			};
		});
		obj.push({ isEmpty: true, field: "", val: "", isVar: false, id: localId });
		setCtableMaxId(localId);
		setCommandTable(obj);
	};

	const renderConditionTree = (tree) => {
		if (!tree) tree = conditionTree;
		return tree.map((cnd) => {
			return (
				<div id={cnd.id} style={{ marginLeft: 50 }}>
					<button onClick={() => addOrRemoveCond(cnd.id)}>
						{cnd.isEmpty ? "+" : "-"}
					</button>
					{cnd.isEmpty ? (
						" ------"
					) : cnd.val === "contains" ? (
						<>
							<select
								className="form-select"
								style={{ marginLeft: 5 }}
								value={cnd.target}
								onChange={(e) => containsSelect(e.target.value, cnd.id)}
							>
								<option value="title">title</option>
								<option value="body">body</option>
							</select>
							<select
								className="form-select"
								style={{ marginLeft: 5 }}
								value={cnd.val}
								onChange={(e) => treeSelect(e.target.value, cnd.id)}
							>
								<option value="always-true">always-true</option>
								<option value="always-false">always-false</option>
								<option value="and">and</option>
								<option value="or">or</option>
								<option value="contains">contains</option>
								<option value="not">not</option>
							</select>
							<input
								type="text"
								style={{ marginLeft: 5 }}
								value={cnd.pattern}
								onChange={(e) => containsInput(e.target.value, cnd.id)}
							></input>
						</>
					) : (
						<>
							<select
								className="form-select"
								style={{ marginLeft: 5 }}
								value={cnd.val}
								onChange={(e) => treeSelect(e.target.value, cnd.id)}
							>
								<option value="always-true">always-true</option>
								<option value="always-false">always-false</option>
								<option value="and">and</option>
								<option value="or">or</option>
								<option value="contains">contains</option>
								<option value="not">not</option>
							</select>
							{cnd.children ? renderConditionTree(cnd.children) : ""}
						</>
					)}
				</div>
			);
		});
	};

	const renderCommandTable = () => {
		return commandTable.map((cmd) => {
			return (
				<tr id={cmd.id}>
					<td>
						<button onClick={() => addOrRemoveCommand(cmd.id)}>
							{cmd.isEmpty ? "+" : "-"}
						</button>
					</td>
					{cmd.isEmpty ? (
						""
					) : (
						<>
							<td>
								<select
									className="form-select"
									style={{ marginLeft: 5 }}
									value={cmd.field}
									onChange={(e) => tableFieldSelect(e.target.value, cmd.id)}
								>
									<option value="min_discount">min_discount</option>
									<option value="max_discount">max_discount</option>
									<option value="effective_discount" selected>
										effective_discount
									</option>
									<option value="state_discount">state_discount</option>
									<option value="product_type">product_type</option>
									<option value="is_steep">is_steep</option>
									<option value="is_significant">is_significant</option>
									<option value="is_storewide">is_storewide</option>
									<option value="is_clearance">is_clearance</option>
									<option value="holiday_tag">holiday_tag</option>
									<option value="free_ship_condition">
										free_ship_condition
									</option>
									<option value="state_discount_val">state_discount_val</option>
									<option value="is_steep_threshold">is_steep_threshold</option>
									<option value="is_signifiant_threshold">
										is_signifiant_threshold
									</option>
								</select>
							</td>
							<td style={{ width: 155 }}>
								{cmd.field === "is_storewide" ||
								cmd.field === "is_steep" ||
								cmd.field === "is_significant" ||
								cmd.field === "is_clearance" ||
								cmd.field === "is_signifiant_threshold" ||
								cmd.field === "is_steep_threshold" ? (
									<select
										className="form-select"
										style={{ marginLeft: 5 }}
										value={cmd.val}
										onChange={(e) =>
											tableValInput(e.target.value === "true", cmd.id)
										}
									>
										<option value="false">False</option>
										<option value="true">True</option>
									</select>
								) : (
									<input
										type="text"
										style={{ marginLeft: 5 }}
										value={cmd.val}
										onChange={(e) => tableValInput(e.target.value, cmd.id)}
									/>
								)}
							</td>
							<td>
								{cmd.field === "is_storewide" ||
								cmd.field === "is_steep" ||
								cmd.field === "is_significant" ||
								cmd.field === "is_clearance" ||
								cmd.field === "is_signifiant_threshold" ||
								cmd.field === "is_steep_threshold" ? (
									""
								) : (
									<input
										type="checkbox"
										style={{ marginLeft: 5 }}
										checked={cmd.isVar}
										onChange={() => tableIsVarChange(cmd.id)}
									/>
								)}
							</td>
						</>
					)}
				</tr>
			);
		});
	};

	const validateCommand = () => {
		commandTable.forEach((cmd) => {
			if (!cmd.isEmpty) {
				if (
					cmd.field === "min_discount" ||
					cmd.field === "max_discount" ||
					cmd.field === "effective_discount" ||
					cmd.field === "state_discount_val"
				) {
					let val = cmd.val;
					if (parseFloat(val) % 1 === 0) {
						toast.warn(cmd.field + " field must be float number");
						return false;
					}
				}
			}
		});
		return true;
	};

	const createRule = async () => {
		if (!validateCommand()) {
			return;
		}
		let cnd = parseConditionToXML();
		let cmd = parseCommandToJSON();
		let data = {
			condition: cnd.replaceAll(/\n|\t/g, ""),
			command: cmd.replaceAll(/\s|\n|\\\//g, ""),
			priority: priorityCreate,
			type: ruleTypeCreate,
		};
		if (ruleTypeCreate === "OnceOff") {
			data["start_date"] = startDateCreate;
			data["end_date"] = endDateCreate;
		}
		if (!isGlobalCreate) {
			data["domain"] = selectedDomain.domain;
		} else {
			data["domain"] = "";
		}
		await axios
			.post(
				getLunaUrl("v1/system-discount/rules/create/"),
				data,
				getNumericalHeader()
			)
			.then((res) => {
				toast.success("Rule created successfully");
				setRuleCreation(false);
			})
			.catch((err) => toast.error("An error occurred, please try again"));
	};

	const toggleEditModal = (id) => {
		setIdEdit(id);
		setRuleEdit(!ruleEdit);
	};

	const loadEmail = async () => {
		if (!emailId || emailId === "") {
			toast.warn("Please fill in a valid email ID");
			return;
		}

		await axios
			.get(getLunaUrl(`v1/emailfeed/data/${emailId}/`), getNumericalHeader())
			.then((res) => {
				const data = res.data.email_detail;
				toast.success("Email loaded successfully");
				setTestSubject(data.subject || "");
				setTestBody(data.message?.text_annotations || "");
			})
			.catch((err) => {});
	};

	const checkEmailOutput = async () => {
		const payload = {
			title: testSubject,
			body: testBody,
			xml: conditionXML,
			command: commandJSON,
		};
		await axios
			.post(
				getLunaUrl(`v1/system-discount/exec/`),
				payload,
				getNumericalHeader()
			)
			.then((res) => {
				const result = res.data.result;
				if (result) {
					toast.success("Email matched");
				} else {
					toast.error("No match found");
				}
				setTestOutput(JSON.stringify(res.data.discount, null, 2));
				setTestOutputData(JSON.stringify(res.data.data, null, 2));
			})
			.catch((err) => {
				toast.error("An error occurred");
			});
	};

	useEffect(() => {
		if (!loaded) return;
		const fetch = () => {
			let domains = uniqueDomains;
			let tkrs = tickers;
			domains
				.filter((domain) => {
					return domain.domain === domain.parent_domain;
				})
				.sort();
			setDomains(domains);
			setSelectedTicker(tkrs[0]);
		};
		fetch();
		setInitialEndDate();
		setInitialStartDate();
	}, [loaded]);

	useEffect(() => {
		if (domains.length > 0) {
			let tmp = domains.filter((dom) => dom.ticker === selectedTicker).sort();
			setSelectedTickerDomains(tmp);
			setSelectedDomain(tmp[0]);
		}
	}, [selectedTicker]);

	useEffect(() => {
		if (!loaded || !selectedDomain || !selectedDomain.domain) return;
		loadRule();
	}, [loaded, selectedDomain]);

	useEffect(() => {
		if (!selectedDomain?.domain || startDate == "" || endDate == "") return;
		loadDiscountData();
	}, [startDate, endDate, selectedDomain]);

	useEffect(() => {
		setStartDateCreate(startDate);
	}, [startDate]);

	useEffect(() => {
		setEndDateCreate(endDate);
	}, [endDate]);

	if (!loaded) {
		return (
			<div className="spinner-wrapper">
				<div className="spinner"></div>
			</div>
		);
	}
	return (
		<Row>
			<Modal
				show={ruleCreation}
				onHide={() => setRuleCreation(false)}
				className="rule-modal"
			>
				<div>
					<Row>
						<Col sm={11}>
							<h3>Rule Create</h3>
						</Col>
						<Col sm={1} onClick={() => setRuleCreation(false)}>
							<i className="dripicons-cross close-icon"></i>
						</Col>
					</Row>
					<br />

					{isGlobalCreate ? (
						""
					) : (
						<Row className="rule-creation-row">
							<Col sm={12} md={4} className="rule-creation-title">
								Domain
							</Col>

							<Col sm={12} md={4}>
								{selectedDomain.domain}
							</Col>
						</Row>
					)}

					<Row className="rule-creation-row">
						<Col sm={12} md={4} className="rule-creation-title mytooltip">
							Type&nbsp;
							<i class="dripicons-information mytooltip-target"></i> :
							<div className="tooltiptext">
								OneTime job only impacts the data between the start date and the
								end date. For other types, the data range doesn't apply.
							</div>
						</Col>

						<Col sm={12} md={4}>
							<select
								className="form-select"
								value={ruleTypeCreate}
								onChange={(e) => setRuleTypeCreate(e.target.value)}
							>
								<option value="OnceOff">One Time</option>
								<option value="Weekly">Weekly</option>
								<option value="Monthly">Monthly</option>
							</select>
						</Col>
					</Row>

					{ruleTypeCreate === "OnceOff" ? (
						<>
							<Row className="rule-creation-row">
								<Col sm={12} md={4} className="rule-creation-title">
									Start Date:
								</Col>

								<Col sm={12} md={4}>
									<input
										type="date"
										value={startDateCreate}
										onChange={(e) => setStartDateCreate(e.target.value)}
									></input>
								</Col>
							</Row>

							<Row className="rule-creation-row">
								<Col sm={12} md={4} className="rule-creation-title">
									End Date:
								</Col>

								<Col sm={12} md={4}>
									<input
										type="date"
										value={endDateCreate}
										onChange={(e) => setEndDateCreate(e.target.value)}
									></input>
								</Col>
							</Row>
						</>
					) : (
						""
					)}

					<Row className="rule-creation-row">
						<Col sm={12} md={4} className="rule-creation-title mytooltip">
							Apply Globally&nbsp;
							<i class="dripicons-information mytooltip-target"></i> :
							<div className="tooltiptext">
								If this rule will apply to all the domains.
							</div>
						</Col>

						<Col sm={12} md={4}>
							<div class="form-check">
								<CustomInput
									type="switch"
									id="isGlobalSwitch"
									checked={isGlobalCreate}
									onClick={(e) => setGlobalCreate(e.target.checked)}
								/>
							</div>
						</Col>
					</Row>

					<Row className="rule-creation-row">
						<Col sm={12} md={4} className="rule-creation-title mytooltip">
							Priority&nbsp;
							<i class="dripicons-information mytooltip-target"></i> :
							<div className="tooltiptext">
								Higher priority will override the result of lower priority.
							</div>
						</Col>

						<Col sm={12} md={4}>
							<input
								type="number"
								min="1"
								value={priorityCreate}
								onChange={(e) => setPriorityCreate(e.target.value)}
							/>
						</Col>
					</Row>

					<Row className="rule-creation-row">
						<Col sm={12} className="rule-creation-title">
							Condition(Tree)
						</Col>
					</Row>

					<Row className="rule-creation-row">
						<Col sm={12}>
							<div>root(and):</div>
							{renderConditionTree()}
						</Col>
					</Row>

					<Row className="rule-creation-row">
						<Col sm={12}>
							<button onClick={parseConditionToXML}>ToXML</button>
							<button onClick={parseXMLtoCondition}>FromXML</button>
						</Col>
					</Row>

					<Row className="rule-creation-row">
						<Col sm={12} className="rule-creation-title">
							Condition(XML):
						</Col>
					</Row>

					<Row className="rule-creation-row">
						<Col sm={12} style={{ marginTop: 5 }}>
							<textarea
								id="condition-xml-textarea"
								class="form-control"
								style={{ height: 120 }}
								spellcheck="false"
								value={conditionXML}
								onChange={(e) => setConditionXML(e.target.value)}
							></textarea>
						</Col>
					</Row>

					<Row className="rule-creation-row">
						<Col sm={12} className="rule-creation-title">
							Command(Table):
						</Col>
						<Col sm={12}>
							<table>
								<thead>
									<tr>
										<td></td>
										<td>&nbsp;&nbsp;Field</td>
										<td>&nbsp;&nbsp;Value</td>
										<td>&nbsp;&nbsp;Is Variable</td>
									</tr>
								</thead>
								<tbody>{renderCommandTable()}</tbody>
							</table>
						</Col>
					</Row>

					<Row className="rule-creation-row">
						<Col sm={12}>
							<button onClick={parseCommandToJSON}>ToJSON</button>
							<button onClick={parseJSONtoCommand}>FromJSON</button>
						</Col>
					</Row>

					<Row className="rule-creation-row">
						<Col sm={12} className="rule-creation-title">
							Command(JSON):
						</Col>

						<Col sm={12} style={{ marginTop: 5 }}>
							<textarea
								id="command-json-textarea"
								class="form-control"
								style={{ height: 100 }}
								spellcheck="false"
								value={commandJSON}
								onChange={(e) => setCommandJSON(e.target.value)}
							></textarea>
						</Col>
					</Row>

					<Row style={{ marginTop: 50 }} className="rule-creation-row">
						<Col sm={12} className="rule-creation-title">
							<button
								class="btn btn-outline-primary rule-btns"
								onClick={createRule}
							>
								Add To Database
							</button>
						</Col>
					</Row>
					<br />
					<br />
					<h3>Test</h3>
					<Row className="rule-creation-row">
						<Col md={4} className="rule-creation-title">
							Load Email By ID:
						</Col>

						<input
							type="text"
							value={emailId}
							onChange={(e) => setEmailID(e.target.value)}
							style={{ marginRight: 10 }}
						/>
						<button onClick={loadEmail}>Load Email</button>
					</Row>

					<Row className="rule-creation-row">
						<Col sm={12} className="rule-creation-title">
							Subject:
						</Col>

						<Col sm={12} style={{ marginTop: 5 }}>
							<textarea
								class="form-control"
								style={{ height: 100 }}
								spellcheck="false"
								value={testSubject}
								onChange={(e) => setTestSubject(e.target.value)}
							></textarea>
						</Col>
					</Row>

					<Row className="rule-creation-row">
						<Col sm={12} className="rule-creation-title">
							Body:
						</Col>

						<Col sm={12} style={{ marginTop: 5 }}>
							<textarea
								class="form-control"
								style={{ height: 100 }}
								spellcheck="false"
								value={testBody}
								onChange={(e) => setTestBody(e.target.value)}
							></textarea>
						</Col>
					</Row>

					<Row style={{ marginTop: 50 }} className="rule-creation-row">
						<Col sm={12} className="rule-creation-title">
							<button
								class="btn btn-outline-primary rule-btns"
								onClick={checkEmailOutput}
							>
								Check Output
							</button>
						</Col>
					</Row>

					<Row className="rule-creation-row">
						<Col sm={12} className="rule-creation-title">
							Results:
						</Col>

						<Col>{testOutput}</Col>
					</Row>

					<Row className="rule-creation-row">
						<Col sm={12} className="rule-creation-title">
							Variables:
						</Col>

						<Col>{testOutputData}</Col>
					</Row>
				</div>
			</Modal>
			<Modal
				show={ruleEdit}
				onHide={() => {
					setRuleEdit(false);
					loadRule();
				}}
				backdrop="static"
				className="rule-modal"
			>
				<RuleEdit
					id={idEdit}
					isModal={true}
					domains={uniqueDomains}
					hide={() => setRuleEdit(false)}
				></RuleEdit>
			</Modal>
			<Col sm={12} md={3}>
				<div className="card">
					<div className="card-title"></div>
					<div className="card-body">
						<Row>
							<Col sm={12} className="task-dropdown-title">
								Ticker:{" "}
							</Col>
						</Row>
						<br />

						<Row>
							<Col sm={12}>
								<Dropdown>
									<Dropdown.Toggle
										variant="outline-dark"
										id="dropdown-basic"
										as={CustomToggle}
										className="task-dropdown-selected"
									>
										{selectedTicker}
									</Dropdown.Toggle>

									<Dropdown.Menu as={CustomMenu} className="task-dropdown">
										{tickers.map((ticker) => {
											return (
												<Dropdown.Item
													key={ticker}
													eventKey={ticker}
													onClick={() => setSelectedTicker(ticker)}
												>
													{ticker}
												</Dropdown.Item>
											);
										})}
									</Dropdown.Menu>
								</Dropdown>
							</Col>
						</Row>
						<br />

						<Row>
							<Col sm={12} className="task-dropdown-title">
								Domain:{" "}
							</Col>
						</Row>
						<br />

						<Row>
							<Col sm={12}>
								<Dropdown>
									<Dropdown.Toggle
										variant="outline-dark"
										id="dropdown-basic"
										as={CustomToggle}
										className="task-dropdown-selected"
									>
										{selectedDomain.domain}
									</Dropdown.Toggle>

									<Dropdown.Menu as={CustomMenu} className="task-dropdown">
										{selectedTicker && selectedTickerDomains
											? selectedTickerDomains.map((domain) => {
													return (
														<Dropdown.Item
															key={domain.domain}
															eventKey={domain.domain}
															onClick={() => setSelectedDomain(domain)}
														>
															{domain.domain}
														</Dropdown.Item>
													);
											  })
											: domains.map((domain) => {
													return (
														<Dropdown.Item
															key={domain.domain}
															eventKey={domain.domain}
															onClick={() => setSelectedDomain(domain)}
														>
															{domain.domain}
														</Dropdown.Item>
													);
											  })}
									</Dropdown.Menu>
								</Dropdown>
							</Col>
						</Row>
						<br />
						<br />
						<Row>
							<Col sm={12}>
								<button
									class="btn btn-primary rule-btns"
									onClick={() => setRuleCreation(true)}
								>
									Create Rule
								</button>
							</Col>
						</Row>
						<br />
					</div>
				</div>
				<RegularExpressionRules></RegularExpressionRules>
			</Col>

			<Col sm={12} md={9}>
				<RuleTable
					totalData={ruleData}
					loadRule={loadRule}
					dataFetching={loadingRule}
					deleteRule={deleteRule}
					toggleWindow={toggleEditModal}
				/>
				<Card style={{ padding: "10px 40px", flexDirection: "row" }}>
					<div
						className="task-dropdown-title"
						style={{ marginRight: 20, width: "48%" }}
					>
						Start Date:{" "}
						<input
							name="date"
							id="exampleDate"
							value={tempStartDate}
							onChange={(e) => setTempStartDate(e.target.value)}
							onBlur={changeStartDate}
							type="date"
							class="form-control"
						></input>
					</div>
					<div
						className="task-dropdown-title"
						style={{ marginRight: 20, width: "48%" }}
					>
						End Date:{" "}
						<input
							name="date"
							id="exampleDate"
							value={tempEndDate}
							onChange={(e) => setTempEndDate(e.target.value)}
							onBlur={changeEndDate}
							type="date"
							class="form-control"
						></input>
					</div>
				</Card>
				<DeepDiscountTable
					dataFetching={loadingDiscount}
					totalData={displayData}
					appliedData={appliedData}
					applyRule={applyRule}
					loadData={loadDiscountData}
					showApplied={showApplied}
				/>
			</Col>
		</Row>
	);
};

export default DiscountTable;
